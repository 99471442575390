import React, { useEffect, useState,useRef } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import { io } from 'socket.io-client';
import userSupportImg from './img/support-icon.png';
import Swal from 'sweetalert2';
import ImageModal from './ImageModal'; // Import the modal component
import 'bootstrap/dist/css/bootstrap.min.css';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import './Chat.css';
import './chatStyle.css';
import {Helmet} from "react-helmet";

// Connect to the socket.io server
const socket = io(process.env.REACT_APP_CHAT_SOCKET, {
  transports: ['websocket'], // Use only WebSocket transport
  query: {
    token: localStorage.getItem("token"),
    userType: 'front',
  }
});

function ChatSupport() {
  
  const [messages, setMessages] = useState([]);
  const conversationRef = useRef(null);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(true); // Loading state
  const [sender, setSender] = useState('sent');
  const [senderId , setSenderId] = useState(localStorage.getItem("userId"));
  const [receiverId , setReceiverId] = useState('67b23b6687afca277eefd6b4');
  const [chatId , setChatId] = useState(localStorage.getItem("visiterChatId"));
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal
  const [currentImageSrc, setCurrentImageSrc] = useState(''); // State for current image
  const [showOptions, setShowOptions] = useState(true); // Show question with options
  const [selectedOption, setSelectedOption] = useState(''); // Store selected option
  const [lastMsgId , setLastMsgId] = useState(null);
  const [seenTimestamps, setSeenTimestamps] = useState(new Set());
  const token = localStorage.getItem("token");
  // ----------  This code for audio recording from here ----------
  const [recording, setRecording] = useState(false);
  const [timer, setTimer] = useState(0);
  const [audioChunks, setAudioChunks] = useState([]);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioCancel, setAudioCancel] = useState(false);
  const isCanceledRef = useRef(false);
  const audioRef = useRef(null); // Update the path according to your structure
  // Timer interval
  useEffect(() => {
    let interval = null;
    if (recording) {
      interval = setInterval(() => {
        setTimer((prev) => prev + 1);
      }, 1000); // Update timer every second
    } else if (!recording && timer !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [recording, timer]);

 // Function to stop the recording (save and send audio)
const stopRecording = () => {
  console.log("........................ STOPING .....................")
  if (mediaRecorder) {
    isCanceledRef.current = false; // Reset cancel flag
    mediaRecorder.stop(); // Stop the recorder
    setRecording(false);
  }

};

// Function to cancel the recording (discard audio)
const cancelRecording = () => {
  console.log("........................ CANCEL .....................")
  if (mediaRecorder) {
    isCanceledRef.current = true; // Set cancel flag
    mediaRecorder.stop(); // Stop the recorder
    setRecording(false);
  }
};

// Function to start recording
const startRecording = async () => {
  try {
    const chunks = []; // Local variable to store audio chunks

    setTimer(0); // Reset timer when starting a new recording
    isCanceledRef.current = false; // Reset cancel state before recording
    
    // Access the user's microphone
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const recorder = new MediaRecorder(stream);

    // Log if the recorder is successfully created
    console.log("MediaRecorder initialized", recorder);

    // Event for data availability
    recorder.ondataavailable = (event) => {
      console.log("ondataavailable event", event.data.size); // Log data size
      if (event.data.size > 0) {
        chunks.push(event.data); // Add chunk to local variable
      }
    };

    // Event when recording stops (Triggered by stop or cancel)
    recorder.onstop = () => {
      console.log("Recording stopped");
      const audioBlob = new Blob(chunks, { type: 'audio/wav' }); // Create Blob from local chunks
      console.log("Blob size after stop:", audioBlob.size);

      if (!isCanceledRef.current) {
        // Only send the audioBlob if the recording is not canceled
        if (audioBlob.size > 0) {
          sendAudioMessage(audioBlob);
        } else {
          console.error("Audio Blob is empty after recording");
        }
      } else {
        console.log("Recording canceled.");
      }

      // Stop all audio tracks to release the microphone
      stream.getTracks().forEach((track) => track.stop());
    };

    // Start recording and ensure data availability every 1 second
    recorder.start(1000); // Pass timeslice (1000ms = 1 second)

    setMediaRecorder(recorder);
    setRecording(true);
  } catch (error) {
    console.error("Error accessing microphone:", error);
    Swal.fire({
      icon: 'error',
      title: 'Microphone Access Denied',
      text: 'Please allow microphone access in your browser settings.',
    });
  }
};

  // Send audio message
  const sendAudioMessage = async (audioBlob) => {
    
    if(audioCancel){
      return false;
    }
    const formData = new FormData();
    formData.append('TransactionFile', audioBlob, 'recording.wav');
    formData.append('fileType', 'audio');
    console.log("Sending audioBlob of size : ----- 1 ------ ", audioBlob);
    // Send the FormData to the API
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}v1/user/receipt-upload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`, // Include the Bearer token
          },
        }
      );

      console.log("File uploaded successfully:", response.data);

      // Create a new message object
      const newMessage = {
        message: '', // Leave the text empty for file uploads
        file: response.data.path, // Store the file path for rendering
        fileType: 'AUDIO', // Get the file type
        senderId: senderId, // Add sender ID
        receiverId: receiverId, // Add receiver ID
        createdAt: new Date(),
      };

      // Emit the new message via socket
      socket.emit('sendMessage', newMessage);
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setLoading(false);
    } catch (error) {
      console.error("Error uploading file:", error);
      setLoading(false);
    }
  };
  // Format time as MM:SS
  const formatTimer = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };


  // Question and predefined options
  const question = 'Hello, how can we help you? Please select one of the options below to help us better understand your query:';
  const predefinedOptions = [
    'একাউন্ট সমস্যা',
    'ডিপোজিট সমস্যা',
    'উইথড্র সমস্যা',
    'গেম সমস্যা',
    'প্রোমোশন অফার',
    'অন্যান্য সমস্যা'
  ];

  // Listen for incoming messages from the server
  useEffect(() => {
    // Log connection status
    socket.on('connect', () => {
      console.log('Connected to the server');
    });

    // Handle disconnection
    socket.on('disconnect', () => {
      console.log('Disconnected from the server');
    });

    // Handle connection errors
    socket.on('connect_error', (err) => {
      console.error('Connection Error:', err);
    });
    
    if( chatId != 'null'){
      console.log("chatId is ----- : ",chatId) 
      
      socket.emit('getChatHistory', chatId,function(data){
        console.log("data getHistoryData--- : ",JSON.stringify(data))
         // Set loading to false when data is fetched
         setLoading(false); 
      });
    } else {
      setLoading(false); 
    }

    socket.on('chatHistory',function(data){
      console.log("data chatHistory : ",data)
      //setMessages(data);
      setMessages((prevMessages) => [...prevMessages, ...data]);
      setLoading(false); 
    });

    socket.on('newMessage',function(data){
      
      let chatIdOld = chatId;
      console.log("data newMessage --- : ",JSON.stringify(data))
      setChatId(data.chatId)
      localStorage.setItem("visiterChatId",data.chatId)

      if (audioRef.current) {
        console.log("audioRef.current : ",audioRef.current)
        audioRef.current.load();
        audioRef.current.play();
      }

      if(data.sender != senderId){

        // setMessages((prevMessages) => [...prevMessages, data]);
        // Update the seen timestamps
        // setSeenTimestamps((prevSeen) => new Set(prevSeen).add(data.createdAt));
        socket.emit('getChatHistory', chatId,function(data){
          setLoading(false); 
        });
        socket.emit('markAsRead',chatId,senderId);

        // setTimeout(() => {
        //   // Check if the timestamp has already been seen
        //   if (!seenTimestamps.has(data.createdAt)) {
        //     console.log("Adding new message:", data);
        //     // Update state with the new message
        //     setMessages((prevMessages) => [...prevMessages, data]);
        //     // Update the seen timestamps
        //     setSeenTimestamps((prevSeen) => new Set(prevSeen).add(data.createdAt));
        //     socket.emit('markAsRead',chatId,senderId);
        //   }
        // }, 1000); // Delay of 1 second
      }

      if( chatIdOld != 'null'){
        console.log("chat id old is not nulll-------------")
        socket.emit('getChatHistory', chatId,function(data){
           setLoading(false); 
        });
      }

      setLoading(false); 
    });

    socket.on('messagesRead',function(data){
      console.log("data newMessage --- : ",JSON.stringify(data))
      setMessages((prevMessages) => prevMessages.map(message => ({ ...message, isRead: true })));
    });

    socket.on('messageDeleted',function(data){
      console.log("data messageDeleted --- : ",JSON.stringify(data))
      setMessages((prevMessages) => prevMessages.filter(message => !data.messageIds.includes(message._id)));
    });

    // Preload audio file (not strictly necessary, but good practice)
    if (audioRef.current) {
      audioRef.current.load();
    }

    // Clean up the socket connection on unmount
    return () => {
      socket.off('connect');
      socket.off('message');
      socket.off('disconnect');
      socket.off('connect_error');
    };
  }, []);


  const handleFileUpload = async (event)  => {
    const file = event.target.files[0];
    setLoading(true)
    const validTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    if (file && validTypes.includes(file.type)) {

      // Create a FormData object
      const formData = new FormData();
      formData.append('TransactionFile', file);
     

      // Send the FormData to the API
      await axios.post(process.env.REACT_APP_API_BASE_URL+'v1/user/receipt-upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`, // Include the Bearer token
        },
      })
      .then((response) => {
        console.log("File uploaded successfully:", response);
        // Handle success response here, e.g., update UI

        // Creating a new message object
        const newMessage = {
          message: '', // Leave the text empty for file uploads
          file: response.data.path, // Store the Base64 string for rendering the image
          fileType: 'IMAGE', // Get the file extension
          senderId: senderId,
          receiverId: receiverId,
          createdAt: new Date()
          //status :'sent'
        };

        // Update the state with the new message
        socket.emit('sendMessage', newMessage);
        setMessages((prevMessages) => [...prevMessages, newMessage]);
        setLoading(false)
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        setLoading(false)
        // Handle error response here
      });

    } else {
      Swal.fire({
        icon: 'error',
        title: 'Invalid File!',
        text: 'Please upload a valid image file (PNG, JPEG, JPG).',
      });
      setLoading(false)
    }
  }

  // Handle message sending
  const handleSend = (e) => {
    e.preventDefault();
    if (input.trim()) {
      const message = input;
      //senderId, receiverId, message, file, fileType
      setMessages([...messages, { message: message, senderId: senderId,receiverId : receiverId ,timestamp:new Date(),fileType: 'text'}]);

      // Emit the message to the server
      console.log("message : ",{ message: message, senderId: senderId,receiverId : receiverId  ,fileType: 'text'});
      socket.emit('sendMessage', { message: message, senderId: senderId,receiverId : receiverId  ,fileType: 'text'});
      // Toggle the sender for the next message
      //setSender(sender === "sent" ? "received" : "sent");
      setInput('');
    }
  };

  useEffect(()=>{
    // Scroll to the bottom of the conversation container
      if (isAtBottom && conversationRef.current) {
        // Scroll to the bottom only if the user is at the bottom
        conversationRef.current.scrollTop = conversationRef.current.scrollHeight;
      }
  },[messages,isAtBottom])

  const handleScroll = () => {
    if (conversationRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = conversationRef.current;
      // Determine if user is at the bottom
      setIsAtBottom(scrollTop + clientHeight >= scrollHeight - 1);
    }
  };

  // Function to format only time from the timestamp
  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString("en-US", {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };

  const openModal = (imageSrc) => {
    setCurrentImageSrc(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Handle selecting an option
  const handleOptionClick = (option) => {
    setLoading(true);  // Start loading
    setSelectedOption(option); // Set the selected option
    setShowOptions(false); // Hide the question and options
    if(senderId && receiverId){
      socket.emit('sendMessage', { message: option, senderId: senderId, receiverId: receiverId,fileType:'text',file:null});
      socket.emit('getChatHistory', chatId,function(data){
        console.log("data getHistoryData--- : ",JSON.stringify(data))
        setLoading(false); // Set loading to false when data is fetched
      });
    }

    socket.on('chatHistory',function(data){
      console.log("data chatHistory : ",data)
      setMessages(data);
      setLoading(false); 
    });

    socket.emit('markAsRead',chatId,senderId);

  };

  const handleDelete = (msgId) => {
    console.log("msgId : =====",msgId)
    // Emit delete event to the server
    socket.emit('deleteMessage',chatId,msgId);
    // Update local state to remove the message
    setMessages((prevMessages) => prevMessages.filter(message => message._id !== msgId));
  }


  
  return (
    <div className="page">
        <Helmet>
              
              <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/material-design-iconic-font/2.1.2/css/material-design-iconic-font.min.css"/>

          </Helmet>
      <div className="marvel-device nexus5">
        <div className="top-bar"></div>
        <div className="sleep"></div>
        <div className="volume"></div>
        <div className="camera"></div>
        <div className="screen" >
          <div className="screen-container">
            {/* <div className="status-bar">
              <div className="time"></div>
              <div className="battery"><i className="zmdi zmdi-battery"></i></div>
              <div className="network"><i className="zmdi zmdi-network"></i></div>
              <div className="wifi"><i className="zmdi zmdi-wifi-alt-2"></i></div>
              <div className="star"><i className="zmdi zmdi-star"></i></div>
            </div> */}
            <div className="chat">
              <div className="chat-container">
                <div className="user-bar">
                <Link to="/"><div className="back"><i className="zmdi zmdi-arrow-left"></i></div></Link>
                  <div className="avatar">
                    <img src={userSupportImg} alt="Avatar" />
                  </div>
                  <div className="name">
                    <span>Support</span>
                    <span className="status">online</span>
                  </div>
                  
                  {/* <div className="actions attachment more" onClick={() => document.getElementById('file-upload').click()}>
                    <i className="zmdi zmdi-attachment-alt"></i>
                    </div>
                    <input type="file" 
                        id="file-upload" 
                        style={{ display: 'none' }} 
                        onChange={handleFileUpload}
                        accept="image/png, image/jpeg, image/jpg"
                      />
                  <div className="actions recording more" onClick={recording ? stopRecording : startRecording}>
                    <i className={`zmdi ${recording ? 'zmdi-mic-off' : 'zmdi-mic'}`}></i>
                  </div>
                  {recording && <div className="timer-audio">{formatTimer(timer)}</div>} */}
                </div>
                <div className="conversation">
                  <div className="conversation-container"  onScroll={handleScroll} ref={conversationRef}>
                  {showOptions ? (
                      // Display question with options
                      <div className="question-container">
                        <p>{question}</p>
                        {predefinedOptions.map((option, index) => (
                          <div
                            key={index}
                            className="option"
                            onClick={() => handleOptionClick(option)}
                          >
                            {option}
                          </div>
                        ))}
                      </div>
                    ) : (
                      // Chat view after selecting an option
                      loading ? (
                        <div className="loader-container">
                          <div className="spinner"></div>
                        </div>
                      ) : (
                        messages.map((msg, index) => (
                          <div
                            key={index}
                            className={`message ${(msg?.receiver === senderId || msg?.receiver?._id === senderId) ? 'received' : 'sent'}`}
                          >
                            <div className='action-btn'>
                              <DropdownButton id="dropdown-item-button" title="" variant="link" >
                                {/* <Dropdown.Item as="button" onClick={() => handleEdit(messageId)}>Edit</Dropdown.Item> */}
                                <Dropdown.Item as="button" onClick={() => handleDelete(msg?._id)} >Delete</Dropdown.Item>
                              </DropdownButton>
                            </div>
                            <div className="msg">
                              {msg.message}
                              {msg.file && (
                                <>
                                  {msg.fileType === 'IMAGE' && (
                                    <img
                                      src={process.env.REACT_APP_API_BASE_URL  + msg.file}
                                      alt="Uploaded image"
                                      className="uploaded-image"
                                      onClick={() => openModal(process.env.REACT_APP_API_BASE_URL + msg.file)}
                                      style={{ cursor: 'pointer' }}
                                    />
                                  )}
                                  {msg.fileType === 'AUDIO' && (
                                    <audio controls style={{width:'260px'}}>
                                      <source src={process.env.REACT_APP_API_BASE_URL + msg.file} type="audio/wav" />
                                      Your browser does not support the audio element.
                                    </audio>
                                  )}
                                </>
                              )}
                              <span className="metadata">
                                <span className="time">{(msg.timestamp)?formatTime(msg.timestamp):formatTime(msg.createdAt)}</span>
                              </span>
                              {msg?.isRead == undefined && <span className="status-icon">✔</span>}
                              {msg.isRead == false && <span className="status-icon">✔</span>}
                              {msg.isRead == true && <span className="status-icon">✔✔</span>}
                            </div>
                          </div>
                        ))
                      )
                    )}
                  </div>
                  {/* Input form to send a new message */}
                  {!showOptions && (
                  <form className="conversation-compose" onSubmit={handleSend}>
                    <div className="emoji">
                    <div className="actions attachment more" onClick={() => document.getElementById('file-upload').click()}>
                    <i className="zmdi zmdi-attachment-alt"></i>
                    </div>
                    <input type="file" 
                        id="file-upload" 
                        style={{ display: 'none' }} 
                        onChange={handleFileUpload}
                        accept="image/png, image/jpeg, image/jpg"
                      />
                 
                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" id="smiley">
                        <path d="M9.153 11.603c.795 0 1.44-.88 1.44-1.962s-.645-1.96-1.44-1.96c-.795 0-1.44.88-1.44 1.96s.645 1.965 1.44 1.965zM5.95 12.965c-.027-.307-.132 5.218 6.062 5.55 6.066-.25 6.066-5.55 6.066-5.55-6.078 1.416-12.13 0-12.13 0zm11.362 1.108s-.67 1.96-5.05 1.96c-3.506 0-5.39-1.165-5.608-1.96 0 0 5.912 1.055 10.658 0zM11.804 1.01C5.61 1.01.978 6.034.978 12.23s4.826 10.76 11.02 10.76S23.02 18.424 23.02 12.23c0-6.197-5.02-11.22-11.216-11.22zM12 21.355c-5.273 0-9.38-3.886-9.38-9.16 0-5.272 3.94-9.547 9.214-9.547a9.548 9.548 0 0 1 9.548 9.548c0 5.272-4.11 9.16-9.382 9.16zm3.108-9.75c.795 0 1.44-.88 1.44-1.963s-.645-1.96-1.44-1.96c-.795 0-1.44.878-1.44 1.96s.645 1.963 1.44 1.963z" fill="#7d8489" />
                      </svg> */}
                    </div>
                    <input
                      className="input-msg"
                      name="input"
                      placeholder="Type a message"
                      value={input}
                      onChange={(e) => setInput(e.target.value)}
                      autoComplete="off"
                      autoFocus
                    />
                    {recording ? 
                    <div className="timer-audio">
                      <i class="zmdi zmdi-mic red-recording-dot"></i>
                      {formatTimer(timer)} <button class="close-button" onClick={cancelRecording}>×</button>
                      </div>
                    :
                    <div className="actions recording more" onClick={recording ? stopRecording : startRecording}>
                    <i className={`zmdi ${recording ? 'zmdi-mic-off' : 'zmdi-mic'}`}></i>
                  </div>
                    }
                     
                  
                    {/* <div className="photo"><i className="zmdi zmdi-camera"></i></div> */}
                    {recording ? 
                    <button className="send" type="submit" onClick={recording ? stopRecording : startRecording}>
                    <div className="circle">
                      <i className="zmdi zmdi-mail-send"></i>
                    </div>
                    </button>
                    :
                    <button className="send" type="submit">
                      <div className="circle">
                        <i className="zmdi zmdi-mail-send"></i>
                      </div>
                    </button>
                    }

                    
                  </form>
                  )}
                  {/* Image Modal */}
                  <ImageModal
                    isOpen={isModalOpen}
                    imageSrc={currentImageSrc}
                    onClose={closeModal}
                  />
                  <video  ref={audioRef} controls oncontextmenu="return false;"  style={{display:"none"}}>
                    <source src="https://ag.cricxo.bet/whatsapp_web.mp3" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatSupport;
