import React, { useContext, useState } from "react";
import { Link, useNavigate, NavLink } from "react-router-dom";
import logo from "../assets/images/logo.png";
import appdownloadicon from "../assets/images/header-appdownload-icon.svg";
import headerserviceicon from "../assets/images/header-service-icon.svg";
import { isEmpty, startCase } from "lodash";
import AuthContext from "../context/AuthContext";
import {
  MdArrowBackIosNew,
  MdKeyboardArrowLeft,
  MdOutlineSubject,
} from "react-icons/md";
import MenuSidebar from "./MenuSidebar";
import MenuSidebarNew from "./MenuSidebarNew";
import LanguagePopup from "./LanguagePopup";
import LoginNotification from "./LoginNotification";
import RefreshIcon from "../assets/images/refreshNew.svg";

import { HiOutlineRefresh } from "react-icons/hi";
import { useTranslation } from "react-i18next";
//  import bajifairapp from "../assets/images/bajifaircomapp.apk";
import FlagIcon from "../assets/imagesNew/united-states.png";
import FlagIconBn from "../assets/imagesNew/bangladesh-flag-round-circle-icon.png";
import {
  Container,
  Row,
  Col,
  Navbar,
  NavDropdown,
  Nav,
  Button,
  Offcanvas,
  Form,
} from "react-bootstrap";
import DepositIcon from "../assets/icons/icon-deposit.svg";
import iconWithdrawal from "../assets/icons/icon-withdrawal.svg";
import iconBetReacord from "../assets/icons/icon-bet-records.svg";
import iconTurnover from "../assets/icons/icon-turnover.svg";
import iconRecords from "../assets/icons/icon-records.svg";
import iconProfile from "../assets/icons/icon-info.svg";
import iconLogout from "../assets/icons/icon-login.svg";
import helpers from "../Utils/helpers";

const HeaderNew = () => {
  const [expanded, setExpanded] = useState(false);
  const {
    user,
    handleShowLanguage,
    showLanguage,
    handleShowSidebar,
    showSidebar,
    amounutRefresh,
    refreshAmountLoader,
    userCoins,
    loginUser,
    lang,
    setEn,
    setBr,
    handleShowLoginNotification,
    setShowLoginNotification,
    showLoginNotification,
    logoutUser,
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <div id={"header"} className="login-header-new">
        <div className="login-reg-wrapper">
          <button
            className="bg-transparent border-0 text-white"
            onClick={() => handleShowSidebar()}
          >
            <MdOutlineSubject className="fs-4" />
          </button>
          <figure onClick={() => navigate("/")}>
            <img src={logo} alt="" />
            {/* } */}
          </figure>
          {isEmpty(user) ? (
            <figcaption>
              {" "}
              <Link to="/register"  className="login-btn" >
                {t("Sign_up")}
              </Link>
              <Link to="/login" className="signup-btn">
                {t("Login")}
              </Link>
            </figcaption>
          ) : (
            <>
              <ul
                style={{
                  backgroundImage:
                    "linear-gradient(-180deg, #414141 0%, #000 100%)",
                }}
                className="mb-0"
              >
                <li className="main-wallet">
                  <a
                    id="multiWallet"
                    href="javascript:void(0)"
                    style={
                      refreshAmountLoader
                        ? {
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }
                        : {}
                    }
                    className={"a-wallet ui-link"}
                  >
                    {refreshAmountLoader ? (
                      <p className="loading-bar" id="menuRefreshLoading">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </p>
                    ) : (
                      <ul id="accountCredit">
                        <li>
                          <span style={{ marginRight: "3px" }}>Main</span>
                          <span id="betCredit" className="betCredit">
                            BDT {helpers?.truncateNumber(userCoins?.balance, 3) || 0}
                          </span>
                        </li>
                        <li>
                          <span style={{ marginRight: "3px" }}>Exposure</span>
                          <span
                            id="totalExposure"
                            style={{
                              color: userCoins?.exp > 0 && "white",
                              background: userCoins?.exp && "red",
                              padding: "0 2px",
                              borderRadius: "8px",
                              fontSize: "2.66667vw",
                              marginLeft: "5px",
                            }}
                          >
                            {(Math.floor(userCoins?.exp * 100) / 100).toFixed(
                              2
                            )}
                          </span>
                        </li>{" "}
                        {/* <li className="nums">
                          +<span id="vendorQuantity">5</span>
                        </li> */}
                      </ul>
                    )}
                  </a>
                  <a
                    className={`a-refresh ui-link refresh-header`}
                    id="menuRefresh"
                    href="javascript:void(0)"
                    onClick={() => amounutRefresh()}
                    title="Refresh Main Wallet"
                  >
                    <img
                      src={RefreshIcon}
                      style={{ verticalAlign: "unset" }}
                      alt={""}
                    />
                  </a>
                </li>
              </ul>
              {/* <button
                className="bg-transparent border-0 text-white"
                onClick={() => handleShowSidebar()}
              >
                <MdOutlineSubject className="fs-4" />
              </button> */}
            </>
            // <div
            //   style={{
            //     display: "flex",
            //     justifyContent: "space-between",
            //     alignItems: "center",
            //     width: "100%",
            //   }}
            // >
            //   {" "}
            //   <figcaption>+{user?.user?.username}</figcaption>
            //   <button
            //     className="bg-transparent border-0 text-white"
            //     onClick={() => handleShowSidebar()}
            //   >
            //     <MdOutlineSubject className="fs-4" />
            //   </button>
            // </div>
          )}
          {/* <div className="h-amt">
            
            <div
              className="language-select-div"
              onClick={() => handleShowLanguage()}
              
            >
              <img src={lang == "bn" ? FlagIconBn : FlagIconBn} alt="" />{" "}
              
            </div>

            <Nav className="me-auto">
              <NavDropdown
                title={startCase(user?.user?.username)}
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item
                  onClick={() => setExpanded(false)}
                  as={NavLink}
                  to="/deposit"
                >
                  {" "}
                  <span className="item-icon">
                    <img src={DepositIcon} />
                  </span>{" "}
                  Deposit
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => setExpanded(false)}
                  as={NavLink}
                  to="/withdraw"
                >
                  <span className="item-icon">
                    <img src={iconWithdrawal} />
                  </span>{" "}
                  Withdraw
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => setExpanded(false)}
                  as={NavLink}
                  to="bets-history"
                >
                  <span className="item-icon">
                    <img src={iconBetReacord} />
                  </span>{" "}
                  Bet History
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => setExpanded(false)}
                  as={NavLink}
                  to="/profit-and-loss"
                >
                  <span className="item-icon">
                    <img src={iconTurnover} />
                  </span>
                  Turnover
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => setExpanded(false)}
                  as={NavLink}
                  to="/account-statement"
                >
                  <span className="item-icon">
                    <img src={iconRecords} />
                  </span>
                  Transaction Records
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => setExpanded(false)}
                  as={NavLink}
                  to="/my-profile"
                >
                  <span className="item-icon">
                    <img src={iconProfile} />
                  </span>{" "}
                  Profile
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => logoutUser()}
                  as={NavLink}
                  to="#"
                >
                  <span className="item-icon">
                    <img src={iconLogout} />
                  </span>{" "}
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </div> */}

          {/* <div className="header-right-btn-group">
          
            <a href={"#"}  download="bajifair" target='_blank'><img src={appdownloadicon} /><span>App</span></a>
            <a href="#"><img src={headerserviceicon}/><span >Help</span></a>
            </div> */}
        </div>
        {showSidebar && (
          <MenuSidebarNew
            cancelMenu={handleShowSidebar}
            showSidebar={showSidebar}
          />
        )}
        {showLanguage && (
          <LanguagePopup
            cancelMenu={handleShowLanguage}
            showSidebar={showLanguage}
          />
        )}
        {showLoginNotification && (
          <LoginNotification
            cancelMenu={handleShowLoginNotification}
            showSidebar={showLoginNotification}
          />
        )}

        {showSidebar ? (
          <div className="overlay" onClick={() => handleShowSidebar()}></div>
        ) : (
          ""
        )}
        {showLanguage ? (
          <div className="overlay" onClick={() => handleShowLanguage()}></div>
        ) : (
          ""
        )}
        {showLoginNotification ? (
          <div
            className="overlay"
            onClick={() => handleShowLoginNotification()}
          ></div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default HeaderNew;
